<template>
  <div>
    <v-card class="ma-2">
      <g-overlay :is-relay="isOverlay"/>
      <page-progress :is-loading="isLoading"/>
      <page-title icon="mdi-search-web" title="Link Search"/>
      <v-container class="pt-5" fluid>
        <v-row>
          <v-col cols="12" md="8" offset-md="2">
            <v-form ref="searchForm" v-model="isSearchValid" lazy-validation>
              <g-p-search @applySearch="linkSearchHandler"/>
            </v-form>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12">
            <v-data-table
                :headers="headers"
                :items="invoices"
                class="elevation-1 mt-0"
                dense
            >
              <template v-slot:item.paymentStatus="{ item }">
                <g-payment-status-chip v-if="item.paymentStatus === 100"/>
                <g-payment-status-chip v-else-if="item.paymentStatus === 200" color="green" text="Paid"/>
                <g-payment-status-chip v-else-if="item.paymentStatus === 300" color="lime" text="Pending"/>
                <g-payment-status-chip v-else-if="item.paymentStatus === 400" color="green darken-4" text="Refund"/>
                <g-payment-status-chip v-else-if="item.paymentStatus === 600" color="orange" text="Republish"/>
              </template>
              <template v-slot:item.totalAmount="{item}">
                ${{item.totalAmount}}
              </template>
            </v-data-table>
          </v-col>
        </v-row>
      </v-container>
    </v-card>
  </div>
</template>

<script>
import PageTitle from "../components/PageTitle";
import PageProgress from "../components/PageProgress";
import GPSearch from "../components/GPSearch";
import LinkSearch from "../services/LinkSearch";
import GOverlay from "../components/GOverlay";
import GPaymentStatusChip from "../components/GPaymentStatusChip";
export default {
  name: "LinkSearch",
  components: {
    GPaymentStatusChip,
    GOverlay,
    GPSearch,
    PageProgress,
    PageTitle
  },
  data() {
    return {
      isLoading: false,
      isSearchValid: true,
      invoices: [],
      isOverlay: false,
      headers: [
        {
          text: 'Invoice No.',
          value: 'invoiceNo'
        },
        {
          text: 'Order ID',
          value: 'orderId'
        },
        {
          text: 'Email',
          value: 'email'
        },
        {
          text: 'Status',
          value: 'paymentStatus'
        },
        {
          text: 'Total Amount',
          value: 'totalAmount',
          align: 'right'
        },
      ]
    }
  },
  methods: {
    linkSearchHandler(item) {
      if(!item) return;
      this.isOverlay = true;
      LinkSearch.searchLink({postLink: item})
          .then((response) => {
            this.invoices = response.data;
            this.isOverlay = false;
          })
          .catch((error) => {
            this.isOverlay = false;
          })
    }
  }
}
</script>

<style scoped>

</style>